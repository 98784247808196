import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

export default () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="ABC" content="ABC" />
      <title>UI5CN LAB</title>
      <link rel="icon" type="image/png" href="./icon.png" sizes="16x16" />
    </Helmet>
    <h1>Get started</h1>
  </Layout>
)
